import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  // 定义数组routes[],对应哈希地址与组件之间的关系
  routes: [
    //路由规则
    // {
    //   name: "登录",
    //   path: '/login',
    //   component: () => import('@/views/login/index.vue')
    // },
    {
      name: "首页",
      path: "/index",
      component: () => import("@/views/index/index.vue"),
    },
    {
      name: "通知公告",
      path: "/notice/:id?",
      component: () => import("@/views/notice/index.vue"),
      meta: {
        showFather: true,
      },
      children: [
        {
          name: "公告详情",
          path: "detail",
          component: () => import("@/views/notice/detail.vue"),
          meta: {
            showFather: false,
          },
        },
      ],
    },
    {
      name: "公告详情",
      path: "/notice_detail",
      component: () => import("@/views/notice/detail.vue"),
      meta: {
        showFather: true,
      },
    },
    {
      name: "文章列表",
      path: "/article/:path/:cate_id?",
      component: () => import("@/views/about_xh/index.vue"),
      meta: {
        showFather: true,
      },
      props: true,
    },
    {
      name: "文章详情",
      path: "/article_detail/:article_id",
      component: () => import("@/views/about_xh/detail.vue"),
      meta: {
        showFather: true,
      },
      props: true,
    },
    {
      name: "学会党建",
      path: "/learnPartyBuild",
      component: () => import("@/views/learnPartyBuild/index.vue"),
      meta: {
        showFather: true,
      },
      children: [
        {
          name: "学会党建详情",
          path: "detail",
          component: () => import("@/views/learnPartyBuild/detail.vue"),
          meta: {
            showFather: false,
          },
        },
      ],
    },
    // 路由重定向：强制跳转
    {
      path: "/",
      redirect: "/index",
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/views/404"),
      meta: {
        hidden: true,
      },
    },
  ],
});

export default router;
