import Vue from 'vue'
import App from './App.vue'
import router from '../src/router/index.js'
// import '../src/router/permission.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import "../node_modules/swiper/swiper.min.css"

import VueAwesomeSwiper from 'vue-awesome-swiper'
import './utils/request.js'

Vue.use(ElementUI)

Vue.use(VueAwesomeSwiper)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  //  2 挂载路由
  router: router
}).$mount('#app')
